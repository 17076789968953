import Reflux from 'reflux-react-16'

export default Reflux.createActions([
    'setStartDate',
    'setEndDate',
    'changeQueries',
    'changeSortKey',
    'setSelected',
    'get',
    'getOne',
    'update',
    'remove',
    'endShift',
    'getShiftPriceTable',
    'recalculateZones',
    'sendToShiftbase'
])
