import React from 'react'
import Radium from 'radium'
import moment from 'moment'
import _ from 'underscore'

import ShiftActions from '../../actions/ShiftActions.js'

import {Alert, Button, Form, InputGroup, Modal, Row, Col, Table} from 'react-bootstrap'
import {Colors, Divider, Popup} from '../UI/index.js'
import {DatePicker, TimePicker} from '../forms/index.js'
import EditRouteModal from './EditRouteModal.js'
import Spinner from 'react-spinkit'
import time from '../../../../server/utils/time.js'

class ShiftModal extends React.Component {
    constructor(props) {
        super(props)

        this.initialState = {
            shift: null,
            modalIsOpen: false,
            edit: false,
            loading: false,
            error: ''
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(shift) {
        console.log(shift)

        this.setState({
            modalIsOpen: true,
            shift
        })
    }

    close() {
        this.setState(this.initialState)
    }

    onClickSaveShift() {
        const {shift, edit} = this.state

        if (edit) {
            ShiftActions.update(shift, (err, shift) => {
                if (!err) {
                    this.setState({shift})
                } else {
                    this.setState({error: err})
                }
            })
        }

        this.setState({edit: !edit})
    }

    onChangeValue(key, event) {
        const shift = {...this.state.shift}
        shift[key] = event.target.value
        this.setState({shift})
    }

    onChangeDate(event) {
        const {shift} = this.state
        shift.date = event.target.value
        this.setState({shift})
    }

    onChangeStartTime(event) {
        const {shift} = this.state

        const hour = parseInt(event.target.value.split(':')[0])
        const minute = parseInt(event.target.value.split(':')[1])

        shift.startTime = moment(shift.date).hour(hour).minute(minute).format()
        this.setState({shift})
    }

    onChangeEndTime(event) {
        const {shift} = this.state

        const hour = parseInt(event.target.value.split(':')[0])
        const minute = parseInt(event.target.value.split(':')[1])

        shift.endTime = moment(shift.date).hour(hour).minute(minute).format()
        this.setState({shift})
    }

    onChangeWait(event) {
        const {shift} = this.state
        shift.waitTime = Math.max((parseInt(event.target.value) || 0) * 60, 0)
        this.setState({shift})
    }

    onChangeBreak(event) {
        const {shift} = this.state
        shift.breakTime = Math.max((parseInt(event.target.value) || 0) * 60, 0)
        this.setState({shift})
    }

    onChangeUser(event) {
        const {shift} = this.state
        shift.userId = event.target.value
        this.setState({shift})
    }

    onChangeInvoiceHours(event) {
        const {shift} = this.state
        shift.invoiceHours = event.target.checked
        shift.type = shift.type || 'bike'
        this.setState({shift})
    }

    onChangeType(event) {
        const {shift} = this.state
        shift.type = event.target.value
        shift.cooledTransport = false
        this.setState({shift})
    }

    onChangeCooledTransport(event) {
        const {shift} = this.state
        shift.cooledTransport = event.target.checked
        this.setState({shift})
    }

    onChangeDistance(event) {
        const {shift} = this.state
        shift.distance = parseInt(event.target.value) || 0
        this.setState({shift})
    }

    onClickEditRoute(shift, route) {
        this.editRouteModal.open(shift, route)
    }

    onClickSaveRoute(route, callback) {
        const shift = {...this.state.shift}
        shift.routes[route.index] = route

        ShiftActions.update(shift, (err, shift) => {
            if (!err) {
                this.setState({shift})
            }

            callback(err, shift)
        })
    }

    onClickRecalculateZones(id) {
        this.popup.open('Zones herberekenen', 'Weet je dit zeker?', () => {
            ShiftActions.recalculateZones(id, (err, shift) => {
                if (!err) {
                    this.popup.close()
                    if (shift) {
                        this.setState({shift})
                    }
                } else {
                    this.popup.setError(err)
                }
            })
        })
    }

    onClickViewStop(stop) {
        window.open(`https://www.google.com/maps/search/?api=1&query=${stop.lat},${stop.lon}`)
    }

    render() {
        const {modalIsOpen, shift, edit, loading, error} = this.state
        const {user, users, customers} = this.props

        if (modalIsOpen && shift) {
            let performanceColor = Colors.success

            if (shift.performance < 100) {
                performanceColor = Colors.warning
            }
            if (shift.performance < 80) {
                performanceColor = Colors.danger
            }

            return (
                <Modal show={modalIsOpen} size="lg" onHide={this.close.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Dienst info
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col>
                                <p>Datum</p>
                                {!edit ?
                                    <h6>{moment(shift.date).format('DD-MM-YYYY')}</h6> :
                                    <DatePicker
                                        value={shift.date}
                                        onChange={this.onChangeDate.bind(this)}
                                    />
                                }
                            </Col>
                            <Col>
                                <p>Klant</p>
                                {edit && user?.type === 'Admin' ?
                                    <Form.Select
                                        aria-label="Default select example"
                                        style={{width: 250}}
                                        value={shift.customerId}
                                        onChange={this.onChangeValue.bind(this, 'customerId')}
                                    >
                                        {_.values(customers).map((customer) => {
                                            return <option key={customer._id} value={customer._id}>{customer.name}</option>
                                        })}
                                    </Form.Select> :
                                    <h6>{customers[shift.customerId]?.name}</h6>
                                }

                            </Col>
                        </Row>

                        <Divider style={{marginTop: 16, marginBottom: 32}}/>

                        <Row>
                            <Col>
                                <p>Koerier</p>
                                {!edit ?
                                    <h6>{users[shift.userId]?.name}</h6> :
                                    <Form.Select
                                        aria-label="Default select example"
                                        style={{width: 250}}
                                        value={shift.userId}
                                        onChange={this.onChangeUser.bind(this)}
                                    >
                                        {_.values(users).map((user) => {
                                            return <option key={user._id} value={user._id}>{user.name}</option>
                                        })}
                                    </Form.Select>
                                }
                            </Col>
                            <Col>
                                <p>Performance</p>
                                <h5 style={{color: performanceColor}}>{`${shift.performance || 0} %`}</h5>
                            </Col>
                        </Row>

                        <Divider style={{marginTop: 16, marginBottom: 32}}/>

                        <Row>
                            <Col>
                                <p>Werktijd</p>
                                {!edit ?
                                    <div>
                                        <h6>{`${moment(shift.startTime).format('HH:mm')} - ${moment(shift.endTime).format('HH:mm')}`}</h6>
                                        <h6>{`${parseInt(shift.workedTime / 60)} minuten`}</h6>
                                        {shift.otherWorkedTime > 0 &&
                                            <p>{`${parseInt(shift.otherWorkedTime / 60)} minuten in andere dienst`}</p>
                                        }
                                    </div> :
                                    <div style={{display: 'flex'}}>
                                        <TimePicker
                                            style={{width: 120, marginRight: 15}}
                                            time={moment(shift.startTime).format('HH:mm')}
                                            onChange={this.onChangeStartTime.bind(this)}
                                        />
                                        <TimePicker
                                            style={{width: 120}}
                                            time={moment(shift.endTime).format('HH:mm')}
                                            onChange={this.onChangeEndTime.bind(this)}
                                        />
                                    </div>
                                }
                            </Col>
                            <Col>
                                <Row>
                                    <Col>
                                        <p>Wachttijd</p>
                                        {!edit ?
                                            <h6>{`${parseInt(shift.waitTime / 60)} minuten`}</h6> :
                                            <InputGroup style={{width: 120, marginLeft: 0, marginRight: 15}}>
                                                <Form.Control
                                                    type='number'
                                                    value={parseInt(shift.waitTime / 60).toString()}
                                                    onChange={this.onChangeWait.bind(this)}
                                                />
                                                <InputGroup.Text>min</InputGroup.Text>
                                            </InputGroup>

                                        }
                                    </Col>

                                    <Col>
                                        <p>Pauzetijd</p>
                                        {!edit ?
                                            <h6>{`${parseInt(shift.breakTime / 60)} minuten`}</h6> :
                                            <InputGroup style={{width: 120, marginLeft: 0}}>
                                                <Form.Control
                                                    type='number'
                                                    value={parseInt(shift.breakTime / 60).toString()}
                                                    onChange={this.onChangeBreak.bind(this)}
                                                />
                                                <InputGroup.Text>min</InputGroup.Text>
                                            </InputGroup>

                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Divider style={{marginTop: 16, marginBottom: 32}}/>

                        {edit &&
                            <Form.Group className="mb-3">
                                <Form.Check
                                    label='Factuur op uurbasis'
                                    checked={shift.invoiceHours}
                                    onChange={this.onChangeInvoiceHours.bind(this)}
                                />
                            </Form.Group>
                        }

                        {shift.invoiceHours &&
                            <Row>
                                <Col>
                                    <p>Type bezorging</p>
                                    <div>
                                        {!edit ?
                                            <h6>{shift.type === 'car' ? 'Autobezorging' : 'Fietskoerier'}</h6> :

                                            <Form.Select
                                                aria-label="Default select example"
                                                value={shift.type}
                                                onChange={this.onChangeType.bind(this)}
                                            >
                                                <option value='bike'>Fietskoerier</option>
                                                <option value='car'>Autobezorging</option>
                                            </Form.Select>
                                        }
                                        {shift.type === 'car' &&
                                        <Form.Group className="mb-3">
                                            <Form.Check
                                                label='Gekoeld transport'
                                                checked={!!shift.cooledTransport}
                                                onChange={edit ? this.onChangeCooledTransport.bind(this) : () => {}}
                                            />
                                        </Form.Group>
                                        }
                                    </div>
                                </Col>

                                {shift.distanceFee ?
                                    <Col>
                                        <p>Afstand</p>
                                        {!edit ?
                                            <h6>{`${shift.distance} km`}</h6> :

                                            <InputGroup style={{marginRight: 15}}>
                                                <Form.Control
                                                    type='number'
                                                    value={shift.distance}
                                                    onChange={this.onChangeDistance.bind(this)}
                                                />
                                                <InputGroup.Text>km</InputGroup.Text>
                                            </InputGroup>
                                        }
                                    </Col> :
                                    <Col/>
                                }
                            </Row>
                        }

                        <br/>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Starttijd</th>
                                    <th>Eindtijd</th>
                                    <th>Inpaktijd</th>
                                    <th>Bezorgadres</th>
                                    <th>Niet thuis</th>
                                    <th>Geen zone</th>
                                    <th>Te laat</th>
                                    <th/>
                                </tr>
                            </thead>
                            <tbody>
                                {shift.routes.map((route, routeIndex) => {
                                    route.index = routeIndex

                                    // TODO remove this some time after release
                                    // if (!route.preperationTime) {
                                    if (routeIndex === 0) {
                                        route.preperationTime = time.diff(shift.startTime, route.startTime)
                                    } else {
                                        const previousRoute = shift.routes[routeIndex-1]
                                        route.preperationTime = time.diff(previousRoute.endTime, route.startTime)
                                    }
                                    // }

                                    return (
                                        <tr key={`route${routeIndex}`}>
                                            <td>{routeIndex + 1}</td>
                                            <td>{route.startTime ? moment(route.startTime).format('HH:mm') : ''}</td>
                                            <td>{route.endTime ? moment(route.endTime).format('HH:mm') : ''}</td>
                                            <td>{`${parseInt((route.preperationTime || 0) / 60)} min`}</td>
                                            <td>{route.nrDelivered}</td>
                                            <td>{route.nrNotDelivered}</td>
                                            <td>{route.nrOutOfZone || 0}</td>
                                            <td>{route.nrToLate || 0}</td>
                                            <td>
                                                <Button size='sm' onClick={this.onClickEditRoute.bind(this, shift, route)}>
                                                    <i className='mdi mdi-pencil'/>
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td/>
                                    <td/>
                                    <td/>
                                    <th>Totaal</th>
                                    <td>{shift.nrDelivered}</td>
                                    <td>{shift.nrNotDelivered}</td>
                                    <td/>
                                </tr>
                            </tbody>
                        </Table>


                        <br/>
                        {shift.smartRoutes?.length > 0 &&
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Type</th>
                                        <th>Postcode</th>
                                        <th>Zone</th>
                                        <th>Bezorgd</th>
                                        <th>Aankomsttijd</th>
                                        <th>Aanmeldtijd</th>
                                        <th/>
                                    </tr>
                                </thead>
                                <tbody>
                                    {shift.smartRoutes.map((smartRoute, routeIndex) => {
                                        return _.sortBy(smartRoute.stops, (stop) => stop.finalizedAt).map((stop, stopIndex) => {
                                            return (
                                                <tr key={`stop${routeIndex} ${stopIndex}`}>
                                                    <td>{`${routeIndex+1}.${stopIndex+1}`}</td>
                                                    <td>{stop.attachedData?.DeliveryType || ''}</td>
                                                    <td>{stop.zip || ''}</td>
                                                    <td>{stop.zone || 'Geen zone'}</td>
                                                    <td>{stop.finalizeResult ? 'Ja' : 'Nee'}</td>
                                                    <td>{stop.finalizedAt ? moment(stop.finalizedAt).format('HH:mm') : ''}</td>
                                                    <td style={{color: stop.lateFee ? Colors.danger : ''}}>{stop.createdAt ? moment(stop.createdAt).format('HH:mm') : ''}</td>
                                                    <td>
                                                        <Button size='sm' onClick={this.onClickViewStop.bind(this, stop)}>
                                                            <i className='mdi mdi-map-marker'/>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    })}
                                </tbody>
                            </Table>
                        }

                        {shift.feedback &&
                            <>
                                <Divider style={{marginTop: 16, marginBottom: 32}}/>

                                <h6>Feedback</h6>
                                <p>{shift.feedback}</p>
                            </>
                        }

                        {error &&
                            <Alert variant='danger'>{error}</Alert>
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        {shift.smartRoutes?.length > 0 &&
                            <Button
                                disabled={loading}
                                onClick={this.onClickRecalculateZones.bind(this, shift._id)}
                            >
                                {loading ?
                                    <Spinner fadeIn='none'/> :

                                    'Zones herberekenen'
                                }
                            </Button>
                        }

                        <Button
                            disabled={loading}
                            onClick={this.onClickSaveShift.bind(this)}
                        >
                            {loading ?
                                <Spinner fadeIn='none'/> :
                                !edit ?
                                    <i className='mdi mdi-pencil'/> :
                                    'Opslaan'
                            }
                        </Button>
                    </Modal.Footer>

                    <EditRouteModal ref={(ref) => this.editRouteModal = ref} onChange={this.onClickSaveRoute.bind(this)}/>
                    <Popup ref={(ref) => this.popup = ref}/>
                </Modal>
            )
        } else {
            return <div/>
        }
    }
}

export default Radium(ShiftModal)
