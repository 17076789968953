import React from 'react'
import Reflux from 'reflux-react-16'
import Radium from 'radium'
import moment from 'moment'
import _ from 'underscore'
import aSync from 'async'
import FileSaver from 'file-saver'
import Spinner from 'react-spinkit'


import ShiftStore from '../stores/ShiftStore.js'
import ShiftActions from '../actions/ShiftActions.js'
import CustomerStore from '../stores/CustomerStore.js'
import UserStore from '../stores/UserStore.js'

import {Button, Form, Table, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Colors, SearchBar, Popup} from '../components/UI/index.js'
import {DatePicker} from '../components/forms/index.js'
import ShiftModal from '../components/shifts/ShiftModal.js'
import time from '../../../server/utils/time.js'
import formatNumber from '../utils/formatNumber.js'

class Shifts extends Reflux.Component {
    constructor(props) {
        super(props)

        this.stores = [ShiftStore, CustomerStore, UserStore]
    }

    columns() {
        return [
            {title: 'Shiftbase', key: 'syncedWithShiftbase', render: (shift) => {
                return (
                    <>
                        {shift.syncedWithShiftbase &&
                            <i style={{fontSize: 12, color: Colors.success}} className="mdi mdi-circle"/>
                        }

                        {shift.shiftbaseError &&
                            <OverlayTrigger
                                placement="bottom"
                                delay={{show: 100, hide: 400}}
                                overlay={
                                    <Tooltip id="shiftbaseError-tooltip">
                                        {shift.shiftbaseError}
                                    </Tooltip>
                                }
                            >
                                <i style={{fontSize: 12, color: Colors.danger}} className="mdi mdi-circle"/>
                            </OverlayTrigger>
                        }

                        {!shift.syncedWithShiftbase && !shift.shiftbaseError &&
                            <i style={{fontSize: 12, color: Colors.grey}} className="mdi mdi-circle"/>
                        }
                    </>
                )
            }},
            {title: 'Datum', key: 'date', render: (shift) => moment(shift.date).format('DD-MM-YYYY')},
            {title: 'Klant', key: 'customerName', render: (shift) => shift.customerName},
            {title: 'Werktijd', key: 'workedTime', render: (shift) => {
                const hours = `0${moment.duration(shift.workedTime, 'seconds').hours()}`.slice(-2)
                const minutes = `0${moment.duration(shift.workedTime, 'seconds').minutes()}`.slice(-2)
                return <div style={{color: shift.workedTime >= 3600 * 24 ? Colors.danger : ''}}>{`${hours}:${minutes}`}</div>
            }},
            {title: 'Wachttijd', key: 'waitTime', render: (shift) => {
                const hours = parseInt(moment.duration(shift.waitTime, 'seconds').asHours())
                const minutes = `0${moment.duration(shift.waitTime, 'seconds').minutes()}`.slice(-2)
                return `${hours}:${minutes}`
            }},
            {title: 'Pauzetijd', key: 'breakTime', render: (shift) => {
                const hours = parseInt(moment.duration(shift.breakTime, 'seconds').asHours())
                const minutes = `0${moment.duration(shift.breakTime, 'seconds').minutes()}`.slice(-2)
                return `${hours}:${minutes}`
            }},
            {title: 'Bezorgadres', key: 'nrDelivered', render: (shift) => shift.nrDelivered},
            {title: 'Niet thuis', key: 'nrNotDelivered', render: (shift) => shift.nrNotDelivered},
            {title: 'Geen zone', key: 'nrOutOfZone', render: (shift) => {
                return (
                    <div>
                        {shift.nrOutOfZone > 0 &&
                            <i style={{color: Colors.danger, marginRight: 5}} className="mdi mdi-alert-outline"/>
                        }

                        {shift.nrOutOfZone || 0}
                    </div>
                )
            }},
            {title: 'Koerier', key: 'userName', render: (shift) => shift.userName},

            {title: 'Performance', key: 'performance', render: (shift) => {
                let color = Colors.success

                if (shift.performance < 100) {
                    color = Colors.warning
                }

                if (shift.performance < 80) {
                    color = Colors.danger
                }

                return (
                    shift.endTime?
                        <div style={{fontSize: '110%', color}}>{`${shift.performance || 0} %`}</div> :
                        <div/>
                )
            }},
            {title: 'Feedback', key: 'feedback', render: (shift) => shift.feedback}
        ]
    }

    onClickRemove() {
        const {selectedShifts} = this.state

        if (selectedShifts.length === 1) {
            this.popup.open('Dienst verwijderen', 'Weet je dit zeker?', () => {
                ShiftActions.remove(selectedShifts[0], (err) => {
                    if (!err) {
                        ShiftActions.setSelected([])
                        this.popup.close()
                    } else {
                        this.popup.setError(err)
                    }
                })
            })
        }
    }

    onClickSelectAll() {
        const {filteredShifts, selectedShifts} = this.state

        if (filteredShifts.length === selectedShifts.length) {
            ShiftActions.setSelected([])
        } else {
            ShiftActions.setSelected(_.pluck(filteredShifts, '_id'))
        }
    }

    onClickSelect(id) {
        const selectedShifts = JSON.parse(JSON.stringify(this.state.selectedShifts))
        const index = selectedShifts.indexOf(id)

        if (index === -1) {
            selectedShifts.push(id)
        } else {
            selectedShifts.splice(index, 1)
        }

        ShiftActions.setSelected(selectedShifts)
    }

    onClickView(shift) {
        this.shiftModal.open(shift)
        ShiftActions.setSelected([])
    }

    onClickEndShift(id) {
        this.popup.open('Dienst stoppen', 'Weet je dit zeker?', () => {
            ShiftActions.endShift(id, (err) => {
                if (!err) {
                    this.popup.close()
                } else {
                    this.popup.setError(err)
                }
            })
        })
    }

    onClickRecalculateZones() {
        const {selectedShifts} = this.state

        this.popup.open('Zones herberekenen', 'Weet je dit zeker?', () => {
            const errors = []

            this.popup.setProgress(0, selectedShifts.length)

            aSync.eachOfSeries(selectedShifts, (id, index, next) => {
                ShiftActions.recalculateZones(id, (err) => {
                    if (err) {
                        errors.push(err)
                    }

                    this.popup.setProgress(index+1, selectedShifts.length)

                    next()
                })
            }, () => {
                setTimeout(() => {
                    if (errors.length) {
                        this.popup.setErrors(errors)
                    } else {
                        this.popup.close()
                    }
                    ShiftActions.get(true)
                }, 1000)
            })
        })
    }

    onClickSendToShiftbase() {
        const {startDate, endDate} = this.state

        const dates = time.between(startDate, endDate)

        this.popup.open('Diensten versturen naar Shiftbase', `Alle diensten van ${moment(startDate).format('DD-MM-YYYY')} t/m ${moment(endDate).format('DD-MM-YYYY')} worden naar Shiftbase gestuurd.`, () => {
            const errors = []

            aSync.eachSeries(dates, (date, next) => {
                ShiftActions.sendToShiftbase(date, (err) => {
                    if (err) {
                        errors.push(err)
                    }

                    next()
                })
            }, () => {
                if (errors.length) {
                    this.popup.setErrors(errors)
                } else {
                    this.popup.close()
                }
            })
        })
    }

    onClickExport() {
        const {shifts, users, customers, selectedShifts} = this.state
        const exportShifts = []

        selectedShifts.map((id) => {
            const shift = _.find(shifts, (shift) => shift._id === id)

            if (shift) {
                exportShifts.push(shift)
            }
        })

        const name = 'Diensten.csv'
        const content = ['Omzet;Kosten;Marge;Marge %;Datum;Apotheek;Teamleider;Regio;Medewerker;Fiets/Auto;Per uur/Per pakket;Aantal pakketten;Zone 1;Zone 2;Zone 3; Zone 4;Geen zone;Gem aantal per uur;KM;Werktijd;Bezorgtijd;Inpaktijd;Wachttijd;Pauzetijd']

        exportShifts.map((shift) => {
            const line = []

            const customer = customers[shift.customerId]
            const teamLeader = users[customer.teamLeader]


            // TODO remove this some time after release
            shift.preperationTime = 0
            let nrZone1 = 0
            let nrZone2 = 0
            let nrZone3 = 0
            let nrZone4 = 0
            let nrNoZone = 0

            shift.routes.map((route, routeIndex) => {
                route.index = routeIndex

                nrZone1 += route.deliveries['Zone 1']?.['Aanbelpakket']?.delivered || 0
                nrZone1 += route.deliveries['Zone 1']?.['Brievenbuspakket']?.delivered || 0
                nrZone2 += route.deliveries['Zone 2']?.['Aanbelpakket']?.delivered || 0
                nrZone2 += route.deliveries['Zone 2']?.['Brievenbuspakket']?.delivered || 0
                nrZone3 += route.deliveries['Zone 3']?.['Aanbelpakket']?.delivered || 0
                nrZone3 += route.deliveries['Zone 3']?.['Brievenbuspakket']?.delivered || 0
                nrZone4 += route.deliveries['Zone 4']?.['Aanbelpakket']?.delivered || 0
                nrZone4 += route.deliveries['Zone 4']?.['Brievenbuspakket']?.delivered || 0
                nrNoZone += route.deliveries['Geen zone']?.['Aanbelpakket']?.delivered || 0
                nrNoZone += route.deliveries['Geen zone']?.['Brievenbuspakket']?.delivered || 0

                if (!route.preperationTime && shift.smartRoutes) {
                    const sortedByCreatedAt = _.sortBy(shift.smartRoutes[routeIndex].stops, (stop) => stop.createdAt)
                    const firstRegisteredStop = sortedByCreatedAt[0]
                    const lastRegisteredStop = sortedByCreatedAt[sortedByCreatedAt.length-1]

                    route.preperationTime = time.diff(firstRegisteredStop?.createdAt, lastRegisteredStop?.createdAt) + 120
                }

                if (route.preperationTime) {
                    shift.preperationTime += route.preperationTime
                }
            })

            line.push(formatNumber(shift.revenue))
            line.push(formatNumber(shift.costs))
            line.push(formatNumber(shift.revenue-shift.costs))
            line.push(formatNumber((shift.revenue - shift.costs) / shift.revenue * 100))
            line.push(shift.date)
            line.push(shift.customerName)
            line.push(teamLeader?.name)
            line.push(customer?.region)
            line.push(shift.userName)
            line.push(shift.type === 'car' ? 'Auto' : 'Fiets')
            line.push(shift.invoiceHours ? 'Per uur' : 'Per pakket')
            line.push(shift.nrDelivered)
            line.push(nrZone1)
            line.push(nrZone2)
            line.push(nrZone3)
            line.push(nrZone4)
            line.push(nrNoZone)
            line.push(formatNumber(shift.nrDelivered / ((shift.workedTime - shift.preperationTime - shift.waitTime) / 3600)))
            line.push('') // TODO KM?
            line.push(parseInt(shift.workedTime/60))
            line.push(parseInt(shift.workedTime/60) - parseInt(shift.preperationTime/60))
            line.push(parseInt(shift.preperationTime/60))
            line.push(parseInt(shift.waitTime/60))
            line.push(parseInt(shift.breakTime/60))


            content.push(line.join(';'))
        })

        const csv = content.join('\n')

        // utf-8 BOM to display € correctly - https://stackoverflow.com/questions/56154046/downloading-blob-with-type-text-csv-strips-unicode-bom
        const buffer = new ArrayBuffer(3)
        const dataView = new DataView(buffer)
        dataView.setUint8(0, 0xef)
        dataView.setUint8(1, 0xbb)
        dataView.setUint8(2, 0xbf)
        const read = new Uint8Array(buffer)

        const blob = new Blob([read, csv], {type: 'data:text/csv,charset=utf-8'})
        FileSaver.saveAs(blob, name)


        ShiftActions.setSelected([])
    }

    render() {
        const {filteredShifts, selectedShifts, startDate, endDate, shiftsLoading, shiftQueries, shiftsSortKey, shiftsSortReverse, customers, users} = this.state
        const {user} = this.props

        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%'}}>
                <div style={{display: 'flex', width: '100%', padding: 20}}>
                    <DatePicker
                        style={{width: 190, marginRight: 10}}
                        value={startDate}
                        onChange={(event) => ShiftActions.setStartDate(event.target.value)}
                    />
                    <DatePicker
                        style={{width: 190, marginRight: 10}}
                        value={endDate}
                        onChange={(event) => ShiftActions.setEndDate(event.target.value)}
                    />

                    <SearchBar
                        columns={this.columns()}
                        queries={shiftQueries || []}
                        onChange={ShiftActions.changeQueries}
                    />


                    <div style={{display: 'flex', height: 38}}>
                        {user?.type === 'Admin' &&
                            <Button
                                style={{marginLeft: 10}}
                                onClick={this.onClickSendToShiftbase.bind(this)}
                            >
                                Versturen naar Shiftbase
                            </Button>
                        }

                        {selectedShifts.length > 0 &&
                        <>
                            {user?.type === 'Admin' &&
                                <Button
                                    style={{marginLeft: 10}}
                                    onClick={this.onClickExport.bind(this)}
                                >
                                    CSV Export
                                </Button>
                            }

                            <Button
                                style={{marginLeft: 10}}
                                onClick={this.onClickRecalculateZones.bind(this)}
                            >Zones herberekenen</Button>

                            {selectedShifts.length === 1 &&
                                <Button
                                    style={{marginLeft: 10}}
                                    variant='danger'
                                    onClick={this.onClickRemove.bind(this)}
                                >Verwijder dienst</Button>
                            }
                        </>

                        }
                    </div>
                </div>

                <div style={{background: Colors.light, width: '100%', height: '100%', overflowY: 'scroll'}}>
                    <Table responsive >
                        <thead>
                            <tr>
                                <th>
                                    <Form.Check
                                        checked={filteredShifts.length && selectedShifts.length === filteredShifts.length}
                                        onChange={this.onClickSelectAll.bind(this)}
                                    />
                                </th>
                                {this.columns().map((column) => {
                                    return (
                                        <th
                                            key={column.key}
                                            style={{cursor: 'pointer', userSelect: 'none'}}
                                            onClick={() => ShiftActions.changeSortKey(column.key)}
                                        >
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div>{column.title}</div>
                                                {column.key === shiftsSortKey && shiftsSortReverse &&
                                                    <i className='mdi mdi-sort-descending'/>
                                                }
                                                {column.key === shiftsSortKey && !shiftsSortReverse &&
                                                    <i className='mdi mdi-sort-ascending'/>
                                                }
                                            </div>
                                        </th>
                                    )
                                })}
                                <th/>
                            </tr>
                        </thead>

                        <tbody>


                            {!shiftsLoading && filteredShifts.map((shift) => {
                                return (
                                    <tr key={shift._id}>
                                        <td>
                                            <Form.Check
                                                checked={selectedShifts.indexOf(shift._id) > -1}
                                                onChange={this.onClickSelect.bind(this, shift._id)}
                                            />
                                        </td>
                                        {this.columns().map((column) => {
                                            return <td key={column.key}>{column.render(shift)}</td>
                                        })}
                                        <td>
                                            {shift.endTime ?
                                                <Button size='sm' onClick={this.onClickView.bind(this, shift)}>
                                                    <i className='mdi mdi-eye'/>
                                                </Button> :
                                                <Button size='sm' variant='danger' onClick={this.onClickEndShift.bind(this, shift._id)}>
                                                    <i className='mdi mdi-stop'/>
                                                </Button>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>

                    {shiftsLoading &&
                        <div style={{display: 'flex', width: '100%', justifyContent: 'center', padding: 20}}>
                            <Spinner fadeIn='none'/>
                        </div>
                    }
                </div>

                <ShiftModal
                    user={user}
                    customers={customers}
                    users={users}
                    ref={(ref) => this.shiftModal = ref}
                />

                <Popup ref={(ref) => this.popup = ref}/>
            </div>
        )
    }
}

export default Radium(Shifts)
