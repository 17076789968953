import Reflux from 'reflux-react-16'
import moment from 'moment'
import _ from 'underscore'
import socket from '../../socketio/socketio.js'
import filter from '../utils/filter.js'

import ShiftActions from '../actions/ShiftActions.js'

export default class ShiftStore extends Reflux.Store {
    constructor() {
        super()

        this.state = {
            startDate: moment().startOf('isoweek').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            shifts: [],
            filteredShifts: [],
            selectedShifts: [],
            shiftsLoading: false,
            shiftsSortKey: 'date',
            shiftsSortReverse: true,
            shiftQueries: []
        }

        this.listenables = ShiftActions

        ShiftActions.get(true)

        socket.on('updateShifts', () => {
            ShiftActions.get()
        })
    }

    onSetStartDate(startDate) {
        let {endDate} = this.state

        if (startDate > endDate) {
            endDate = startDate
        }

        this.setState({startDate, endDate})
        ShiftActions.get(true)
    }

    onSetEndDate(endDate) {
        let {startDate} = this.state

        if (endDate < startDate) {
            startDate = endDate
        }

        this.setState({startDate, endDate})
        ShiftActions.get(true)
    }

    onChangeQueries(shiftQueries) {
        const {shifts} = this.state

        const filteredShifts = filter.shifts(shifts, shiftQueries)

        this.setState({filteredShifts, shiftQueries})
    }

    onChangeSortKey(newSortKey) {
        let {filteredShifts, shiftsSortKey, shiftsSortReverse} = this.state

        if (shiftsSortKey === newSortKey) {
            shiftsSortReverse = !shiftsSortReverse
        } else {
            shiftsSortReverse = false
        }

        shiftsSortKey = newSortKey

        filteredShifts = _.sortBy(filteredShifts, shiftsSortKey)

        if (shiftsSortReverse) {
            filteredShifts.reverse()
        }

        this.setState({filteredShifts, shiftsSortKey, shiftsSortReverse})
    }

    onSetSelected(selectedShifts) {
        this.setState({selectedShifts})
    }

    onGet(loading) {
        const {startDate, endDate} = this.state
        this.setState({shiftsLoading: !!loading})

        socket.emit('shifts.get', sessionStorage.token, startDate, endDate, (err, shifts) => {
            if (!err) {
                const {shiftQueries, shiftsSortKey, shiftsSortReverse} = this.state

                let filteredShifts = filter.shifts(shifts, shiftQueries)

                filteredShifts = _.sortBy(filteredShifts, shiftsSortKey)
                if (shiftsSortReverse) {
                    filteredShifts.reverse()
                }

                this.setState({shifts, filteredShifts, shiftsLoading: false})
            }
        })
    }

    onGetOne(id, callback) {
        socket.emit('shifts.getOne', sessionStorage.token, id, callback)
    }

    onUpdate(shift, callback) {
        socket.emit('shifts.update', sessionStorage.token, shift, callback)
    }

    onRemove(id, callback) {
        socket.emit('shifts.remove', sessionStorage.token, id, callback)
    }

    onEndShift(id, callback) {
        socket.emit('shifts.endRoute', sessionStorage.token, id, {}, (err) => {
            if (err) {
                callback(err)
            } else {
                socket.emit('shifts.endShift', sessionStorage.token, id, {}, callback)
            }
        })
    }


    onGetShiftPriceTable(id, callback) {
        socket.emit('shifts.getPriceTable', sessionStorage.token, id, callback)
    }

    onRecalculateZones(...args) {
        socket.emit('shifts.recalculateZones', sessionStorage.token, ...args)
    }

    onSendToShiftbase(...args) {
        socket.emit('shifts.sendToShiftbase', sessionStorage.token, ...args)
    }
}
