import React from 'react'
import Radium from 'radium'
import _ from 'underscore'
import generateUUID from '../../../../server/utils/generateUUID.js'

import CustomerActions from '../../actions/CustomerActions.js'

import {Alert, Button, Modal, Form, InputGroup, Row, Col} from 'react-bootstrap'
import {Address, TimePicker} from '../forms/index.js'
import Spinner from 'react-spinkit'
import MapZones from '../UI/MapZones.js'

import NewCustomer from './NewCustomer.js'

class CustomerModal extends React.Component {
    constructor(props) {
        super(props)
        this.initialState = {
            modalIsOpen: false,
            customer: NewCustomer(),
            loading: false,
            error: '',
            validation: {}
        }

        this.state = JSON.parse(JSON.stringify(this.initialState))
    }

    open(customer) {
        customer = customer ? JSON.parse(JSON.stringify(customer)) : this.state.customer

        console.log(customer)

        this.setState({
            modalIsOpen: true,
            customer
        })
    }

    close() {
        this.setState(this.initialState)
    }

    onChangeValue(key, event) {
        const {customer} = this.state
        if (key === 'zone1Distance' || key === 'zone2Distance' || key === 'zone3Distance' || key === 'zone4Distance') {
            customer[key] = Number.isNaN(parseFloat(event.target.value)) ? '' : parseFloat(event.target.value)
        } else {
            customer[key] = event.target.value
        }

        if (key === 'ais' && event.target.value === 'Receptlocatie' && !customer.apiKey) {
            customer.apiKey = generateUUID()
        }

        if (key === 'ais' && event.target.value === 'Receptlocatie' && !customer.aisId) {
            customer.aisId = `VM-${Math.random().toString().substring(2, 8)}`
        }

        this.setState({customer})
    }

    onChangeChecked(key, event) {
        const {customer} = this.state
        customer[key] = event.target.checked

        if (key === 'invoiceHours') {
            customer.distanceFee = false
        }

        if (key === 'isReistijdAccount') {
            customer.isInwerkAccount = false
        }
        if (key === 'isInwerkAccount') {
            customer.isReistijdAccount = false
        }

        this.setState({customer})
    }

    onAddressChange(address) {
        const {customer} = this.state
        customer.name = address.name
        customer.address = address
        this.setState({customer})
    }

    onSubmit(event) {
        event.preventDefault()
        const {customer} = this.state

        const formValid = this.validate()
        const addressValid = this.addressForm.validate()

        if (addressValid && formValid) {
            this.setState({loading: true, error: ''})


            CustomerActions.update(customer, (err, customer) => {
                if (err || customer.error) {
                    this.setState({error: err || customer.error, loading: false})
                } else {
                    this.close()
                }
            })
        }
    }

    validate() {
        const {customer} = this.state
        const validation = {}
        let valid = true
        let focusId = ''

        if (!customer.messageSet) {
            focusId = 'messageSet'
            validation.messageSet = true
            valid = false
        }

        if (!customer.priceTable) {
            focusId = 'priceTable'
            validation.priceTable = true
            valid = false
        }

        if (!valid) {
            document.getElementById(focusId).focus()
        }

        this.setState({validation})
        return valid
    }

    render() {
        const {customer, error, loading, modalIsOpen, validation} = this.state
        const {users, priceTables, messageSets} = this.props

        return (
            <Modal show={modalIsOpen} size="lg" keyboard={false} backdrop='static' onHide={this.close.bind(this)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {customer._id ? 'Bewerk klant' : 'Nieuwe klant'}
                    </Modal.Title>
                </Modal.Header>

                <Form onSubmit={this.onSubmit.bind(this)}>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        label='Actief'
                                        checked={customer.active || false}
                                        onChange={this.onChangeChecked.bind(this, 'active')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Groep</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        value={customer.groupId || ''}
                                        onChange={this.onChangeValue.bind(this, 'groupId')}
                                    >
                                        <option value=''>Overige</option>
                                        <option value='BENU'>BENU</option>
                                        <option value='Boots'>Boots</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Regio</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        value={customer.region || ''}
                                        onChange={this.onChangeValue.bind(this, 'region')}
                                    >
                                        <option value=''></option>
                                        <option value='Landelijk'>Landelijk</option>
                                        <option value='Almere'>Almere</option>
                                        <option value='Amsterdam'>Amsterdam</option>
                                        <option value='Arnhem'>Arnhem</option>
                                        <option value='Den Haag'>Den Haag</option>
                                        <option value='Den Haag Auto'>Den Haag Auto</option>
                                        <option value='Den Haag-Moerwijk'>Den Haag-Moerwijk</option>
                                        <option value='Den Haag-Rijswijk'>Den Haag-Rijswijk</option>
                                        <option value='Ede'>Ede</option>
                                        <option value='Leiden'>Leiden</option>
                                        <option value='Limburg'>Limburg</option>
                                        <option value='Meppel'>Meppel</option>
                                        <option value='Midden-Oost Brabant'>Midden-Oost Brabant</option>
                                        <option value='Midden-West Brabant'>Midden-West Brabant</option>
                                        <option value='Noord-Holland'>Noord-Holland</option>
                                        <option value='Oost Nederland'>Oost Nederland</option>
                                        <option value='Rotterdam'>Rotterdam</option>
                                        <option value='Utrecht'>Utrecht</option>
                                        <option value='Zeeland'>Zeeland</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Teamleider</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        value={customer.teamLeader || ''}
                                        onChange={this.onChangeValue.bind(this, 'teamLeader')}
                                    >
                                        <option value=''/>
                                        {_.values(users).map((user) => {
                                            if (user.type === 'Admin' || user.type === 'Coördinator') {
                                                return (
                                                    <option key={user._id} value={user._id}>{user.name}</option>
                                                )
                                            }
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Shiftbase afdeling ID</Form.Label>
                                    <Form.Control
                                        value={customer.shiftbaseDepartmentId || ''}
                                        onChange={this.onChangeValue.bind(this, 'shiftbaseDepartmentId')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>AIS</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        value={customer.ais || ''}
                                        onChange={this.onChangeValue.bind(this, 'ais')}
                                        isInvalid={validation.priceTable}
                                    >
                                        <option value=''>Geen AIS</option>
                                        <option value='CGM'>CGM</option>
                                        <option value='PharmaPartners'>PharmaPartners</option>
                                        <option value='Receptlocatie'>Receptlocatie</option>

                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col>
                                {customer.ais &&
                                    <Form.Group className="mb-3">
                                        <Form.Label>AIS ID</Form.Label>
                                        <Form.Control
                                            value={customer.aisId || ''}
                                            onChange={this.onChangeValue.bind(this, 'aisId')}
                                        />
                                    </Form.Group>
                                }
                            </Col>
                        </Row>

                        {customer.ais === 'Receptlocatie' &&
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>API Key</Form.Label>
                                        <Form.Control
                                            value={customer.apiKey || ''}
                                            readOnly={true}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col/>
                            </Row>
                        }

                        <br/>
                        <h5>Adres</h5>
                        <Address
                            address={customer.address}
                            onChange={this.onAddressChange.bind(this)}
                            ref={(ref) => this.addressForm = ref}
                        />

                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Telefoon</Form.Label>
                                    <Form.Control
                                        value={customer.phone || ''}
                                        onChange={this.onChangeValue.bind(this, 'phone')}
                                    />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type='email'
                                        value={customer.email || ''}
                                        onChange={this.onChangeValue.bind(this, 'email')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <br/>
                        <h5>Instellingen</h5>
                        <Row style={{marginBottom: 12}}>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Tarieftabel</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        id='priceTable'
                                        value={customer.priceTable || ''}
                                        onChange={this.onChangeValue.bind(this, 'priceTable')}
                                        isInvalid={validation.priceTable}
                                    >
                                        <option value=''/>
                                        {_.values(priceTables).map((priceTable) => {
                                            return (
                                                <option key={priceTable._id} value={priceTable._id}>{priceTable.name}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Berichtenset</Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        id='messageSet'
                                        value={customer.messageSet || ''}
                                        onChange={this.onChangeValue.bind(this, 'messageSet')}
                                        isInvalid={validation.messageSet}
                                    >
                                        <option value=''/>
                                        {_.values(messageSets).map((messageSet) => {
                                            return (
                                                <option key={messageSet._id} value={messageSet._id}>{messageSet.name}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        type='switch'
                                        label='Cutoff late aanmelding'
                                        checked={customer.useCutoffLateFee || false}
                                        onChange={this.onChangeChecked.bind(this, 'useCutoffLateFee')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                {customer.useCutoffLateFee &&
                                    <Form.Group className="mb-3">
                                        <Form.Label>Cutoff tijd late aanmelding</Form.Label>
                                        <TimePicker
                                            time={customer.cutoffLateFee}
                                            onChange={this.onChangeValue.bind(this, 'cutoffLateFee')}
                                        />
                                    </Form.Group>
                                }
                            </Col>
                        </Row>

                        <Row style={{marginBottom: 12}}>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        type='switch'
                                        label='Cutoff same day'
                                        checked={customer.useCutoffSameDay || false}
                                        onChange={this.onChangeChecked.bind(this, 'useCutoffSameDay')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                {customer.useCutoffSameDay &&
                                    <Form.Group className="mb-3">
                                        <Form.Label>Cutoff tijd same day</Form.Label>
                                        <TimePicker
                                            time={customer.cutoffSameDay}
                                            onChange={this.onChangeValue.bind(this, 'cutoffSameDay')}
                                        />
                                    </Form.Group>
                                }
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        label='Extra apotheek'
                                        checked={customer.extraPharmacy || false}
                                        onChange={this.onChangeChecked.bind(this, 'extraPharmacy')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        label='Voorrijkosten'
                                        checked={customer.callOutFee || false}
                                        onChange={this.onChangeChecked.bind(this, 'callOutFee')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        label='Minimum per dag'
                                        checked={customer.minimumPerDay || false}
                                        onChange={this.onChangeChecked.bind(this, 'minimumPerDay')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 12}}>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        label='Factuur op uurbasis'
                                        checked={customer.invoiceHours || false}
                                        onChange={this.onChangeChecked.bind(this, 'invoiceHours')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                {customer.invoiceHours &&
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        label='Vergoeding per km'
                                        checked={customer.distanceFee || false}
                                        onChange={this.onChangeChecked.bind(this, 'distanceFee')}
                                    />
                                </Form.Group>
                                }
                            </Col>
                            <Col/>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        type='switch'
                                        label='Reistijd account'
                                        checked={customer.isReistijdAccount || false}
                                        onChange={this.onChangeChecked.bind(this, 'isReistijdAccount')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: 12}}>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        type='switch'
                                        label='Inwerk account'
                                        checked={customer.isInwerkAccount || false}
                                        onChange={this.onChangeChecked.bind(this, 'isInwerkAccount')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <h5>Zones</h5>
                        {!customer.useMapZones &&
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Afstand Zone 1</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type='number'
                                                value={customer.zone1Distance || ''}
                                                onChange={this.onChangeValue.bind(this, 'zone1Distance')}
                                            />
                                            <InputGroup.Text id="basic-addon2">km</InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Afstand Zone 2</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type='number'
                                                value={customer.zone2Distance || ''}
                                                onChange={this.onChangeValue.bind(this, 'zone2Distance')}
                                            />
                                            <InputGroup.Text id="basic-addon2">km</InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Afstand Zone 3</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type='number'
                                                value={customer.zone3Distance || ''}
                                                onChange={this.onChangeValue.bind(this, 'zone3Distance')}
                                            />
                                            <InputGroup.Text id="basic-addon2">km</InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Afstand Zone 4</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type='number'
                                                value={customer.zone4Distance || ''}
                                                onChange={this.onChangeValue.bind(this, 'zone4Distance')}
                                            />
                                            <InputGroup.Text id="basic-addon2">km</InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                        }


                        <MapZones
                            customer={customer}
                            zones={customer.zones || []}
                            onChange={this.onChangeValue.bind(this, 'zones')}
                        />

                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        type='switch'
                                        label='Gebruik getekende zones'
                                        checked={customer.useMapZones || false}
                                        onChange={this.onChangeChecked.bind(this, 'useMapZones')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col/>
                        </Row>


                        {error &&
                            <Alert variant='danger'>{error}</Alert>
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Button type="submit" disabled={loading}>
                            {loading ? <Spinner fadeIn='none'/> : 'Opslaan'}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }
}

export default Radium(CustomerModal)
